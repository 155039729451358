import React from 'react';
import useGeolocation from './useLocation';

function App() {
  const { getLocation } = useGeolocation()

  React.useEffect(() => {
    const { REACT_APP_REDIRECT_URL } = process.env;
    (async () => {
      const geolocation = await getLocation()
      const host = window.location.pathname;
      const ppcUrl = 'ppc/bowling-green/eyeglasses/b/';
      const isBowlingGreenPPC = host.toLowerCase().includes('bowling-green');
      if (geolocation.state === "KY" || isBowlingGreenPPC) {        
        window.location.href = `${REACT_APP_REDIRECT_URL}${isBowlingGreenPPC ? host.replace('/', '') : ppcUrl}?redirectedfrom=myeyelab`
      } else {
        window.location.href = `${REACT_APP_REDIRECT_URL}?redirectedfrom=myeyelab`
      }
    })()
  })

  return (
    <></>
  );
}

export default App;
